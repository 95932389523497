* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

body {
  background: #673ab7 linear-gradient(to bottom right, #673ab7 0%, #e040fb 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 100%;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  height: calc(100% - 40px);
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bubble {
  /* background-color: #f0f0f0; */
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.user-container {
  margin-left: 12px;
  font-size: 14px;
}

.prompt {
  font-size: 12px;
  font-weight: bold;
}

.header {
  padding: 12px 16px 12px 16px;
  font-size: 16px;
  background-color: white;
  border-radius: 32px 32px 0 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.pfp-container {
  border-radius: 300px;
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  /* background-image: url(/images/profilePlaceholder.png); */
  background-position: center center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  display: block;
}

.pfp {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;

  border-radius: 20px;
}

.username {
  font-weight: bold;
  margin-bottom: 5px;
}

.textarea-container {
  margin-bottom: 20px;
}

.textarea-container {
  width: 100%;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 0 0 24px 24px;
  position: relative;
  -webkit-appearance: none;
}

textarea {
  width: 100%;
  padding: 18px 24px;
  height: 140px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: transparent;
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 18px;
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
}
.submitbutton {
  text-decoration: none;
}

.animate {
  animation: animate-shake 2s ease-in-out infinite;
}

.dice-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 8px;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  text-align: center;
  touch-action: manipulation;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anonymous-tooltip {
  margin-top: 12px;
  font-size: 14px;
  color: white;
  margin-bottom: 12px;
  text-align: center;
}

@keyframes animate-shake {
  0%,
  10%,
  65%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  30%,
  40% {
    transform: rotate(-1deg) scale(1.05);
  }

  35%,
  45% {
    transform: rotate(1deg) scale(1.05);
  }
}

.download-prompt {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin: 0;
  text-align: center;
  margin-bottom: 10px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: white;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.userGet {
  padding: 20px;
  /* width: calc(100% - 40px); */
  /* position: absolute;
  bottom: 20px; */
}
