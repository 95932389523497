body {
  /* height: 50%; */
}

.containerHome {
  max-width: 600px;
  margin: 0 auto;
}

.topImage {
  max-width: 100%;
}

.section {
  margin: 20px;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 10px;
  align-content: start;
}

.questionAnswer {
  align-self: flex-start;
  margin-bottom: 10px;
}

.question {
  font-weight: bold;
  margin-bottom: 2px;
}

.homeDownload {
  margin: 40px 20px 80px;
  padding: 10px;
}

.headerIcon {
  max-width: 50px;
}

.headerDiv {
  margin: 20px;
  display: flex;
  align-items: center;
}

.headerIcon {
  border-radius: 10px;
  border: 2px solid white;
}

.headerText {
  margin-left: 20px;
  font-size: 40px;
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.downloadLinks {
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 40px 0;
}

.downloadImage {
  width: calc(100% - 60px);
  margin: 20px 30px;
  border-radius: 20px;
}
/* 
.googlePlay {
  width: 100%;
  margin: 0;
} */

.policy {
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 20px;
  text-decoration: underline;
  cursor: pointer;
}
