.containersent {
  width: 85%;
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  margin-top: 16px;
}

.check {
  display: inline-block;
  width: 15vh;
  height: 15vh;
  /* margin-top: 20%; */
  margin-bottom: 20px;
}

.sent {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin: 0;
  margin-bottom: 40px;
}

.download-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: black;
  border-radius: 100px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 20px;
}
.animate {
  animation: animate-shake 2s ease-in-out infinite;
}

.another {
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  display: inline-block;
  border-bottom: 2px solid white;
}
